import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import API from '../../utils/API';
import { setError } from '../error/errorSlice';
import { User } from '../settings/types/user';
import { AxiosError } from 'axios';

interface UserState {
  user: User | null;
  loading: boolean;
}

const initialState: UserState = { user: null, loading: false };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      return { ...state, user: action.payload };
    },
    loading: (state, action) => {
      return { ...state, loading: action.payload };
    },
    reset: () => initialState,
  },
});

export const { loginSuccess, loading, reset } = userSlice.actions;

export const fetchUser =
  (successCb?: () => void, errorCb?: (e: AxiosError) => void): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      const response = await API.get('user');
      dispatch(loginSuccess(response.data.result));
      successCb?.();
    } catch (e: any) {
      errorCb?.(e);
      dispatch(setError(e));
      dispatch(reset());
    } finally {
      dispatch(loading(false));
    }
  };

// selectors
export const isLoading = (state: RootState): boolean => state.user.loading;
export const selectCurrentUser = (state: RootState): User | null =>
  state.user.user;
export const selectCurrentUserGrants = (state: RootState) =>
  state.user.user?.authorities || [];

export default userSlice.reducer;
