import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Form, Input, Select, SelectProps, Transfer } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  departmentLabel,
  groupNameLabel,
  saveLabel,
} from '../../SettingsUsers/AddUser/helper';
import styles from '../../SettingsUsers/styles.module.less';
import { intl } from '../../../../../utils/intl';
import {
  dashboardsByDepartmentSelector,
  getDashboardsByDepartments,
  getUsersByDepartments,
  SettingsState,
  usersByDepartmentSelector,
} from '../../../settingsSlice';
import { DashboardListItem } from '../../../../dashboard/types/dashboard';
import { GroupUser } from '../../../types/group';
import { useWatch } from 'antd/lib/form/Form';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setError } from '../../../../error/errorSlice';

type AddGroupFormProps = {
  hasEditRights: boolean;
  onFinish: (values: any) => void;
  currentGroup: SettingsState['group'];
  departments: SettingsState['departments'];
  loading: boolean;
};

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const requiredFieldMessage = intl.formatMessage({
  id: 'Common.requiredField',
  defaultMessage: 'Обязательное поле',
});

const normalizeName = (user: any) => {
  return user?.name ?? user?.displayName;
};

export const AddGroupForm = (props: AddGroupFormProps) => {
  const { hasEditRights, currentGroup, onFinish, loading, departments } = props;

  const dispatch = useAppDispatch();

  const departmentItems = useMemo(() => {
    return departments.map(i => ({
      label: i.name,
      value: i.id,
    })) as SelectProps['options'];
  }, [departments]);

  const [form] = Form.useForm<{
    name: string;
    departmentId: string;
    dashboardIds: string[];
    userIds: string[];
  }>();

  const watchedName = useWatch('name', form);
  const watchedDepartmentId = useWatch('departmentId', form);
  const watchedDashboardIds = useWatch('dashboardIds', form);
  const watchedUserIds = useWatch('userIds', form);

  const usersByDepartment = useAppSelector(state =>
    usersByDepartmentSelector(state, watchedDepartmentId),
  );

  const dashboardsByDepartment = useAppSelector(state =>
    dashboardsByDepartmentSelector(state, watchedDepartmentId),
  );

  const fetchUsersByDepartment = useCallback(async () => {
    try {
      await dispatch(getUsersByDepartments(watchedDepartmentId));
    } catch (error: any) {
      dispatch(setError(error));
    }
  }, [watchedDepartmentId]);

  const fetchDashboardsByDepartment = useCallback(async () => {
    try {
      await dispatch(getDashboardsByDepartments(watchedDepartmentId));
    } catch (error: any) {
      dispatch(setError(error));
    }
  }, [watchedDepartmentId]);

  useEffect(() => {
    if (
      watchedDepartmentId &&
      (currentGroup === null ||
        watchedDepartmentId !== currentGroup?.department.id)
    ) {
      fetchUsersByDepartment();
      fetchDashboardsByDepartment();
    }
  }, [watchedDepartmentId]);

  const handleDashSearch = (value: string, option: DashboardListItem) =>
    option.name.toLowerCase().includes(value.toLowerCase());

  const handleUserSearch = (value: string, option: GroupUser) => {
    const name = option?.name || option?.displayName || '';

    return name.toLowerCase().includes(value.toLowerCase());
  };

  const departmentIsFreeze = useMemo(() => {
    return watchedDashboardIds?.length > 0 || watchedUserIds?.length > 0;
  }, [watchedDashboardIds, watchedUserIds]);

  return (
    <Form
      {...layout}
      name="basic"
      form={form}
      onFinish={onFinish}
      className={styles.formItem}
      validateMessages={{
        required: requiredFieldMessage,
      }}
      disabled={!hasEditRights}
    >
      <Form.Item
        label={groupNameLabel}
        name="name"
        initialValue={currentGroup?.name}
        rules={[
          { required: true },
          {
            whitespace: true,
            message: (
              <FormattedMessage
                id="GroupSettings.nameWhitespace"
                defaultMessage="Используйте символы"
              />
            ),
          },
          {
            max: 255,
            message: (
              <FormattedMessage
                id="GroupSettings.nameMax"
                defaultMessage="Не более {max} символов"
                values={{ max: 255 }}
              />
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="departmentId"
        label={departmentLabel}
        initialValue={currentGroup?.department.id}
        rules={[{ required: true }]}
      >
        <Select
          options={departmentItems}
          disabled={!hasEditRights || departmentIsFreeze}
        />
      </Form.Item>

      <Form.Item
        name="dashboardIds"
        label={
          <FormattedMessage
            id="Common.changeDashboards"
            defaultMessage="Изменение состава дашбордов"
          />
        }
        rules={[
          {
            required: true,
            type: 'array',
          },
        ]}
        valuePropName="targetKeys"
        className="selectContainer"
        initialValue={currentGroup?.dashboardIds || []}
      >
        <Transfer
          showSearch
          filterOption={handleDashSearch}
          dataSource={dashboardsByDepartment}
          rowKey={item => item.id}
          render={dash => dash.name}
          disabled={!hasEditRights || !watchedDepartmentId}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="Common.changeUsers"
            defaultMessage="Изменение состава пользователей"
          />
        }
        name="userIds"
        initialValue={currentGroup?.userIds || []}
        rules={[
          {
            required: true,
            type: 'array',
          },
        ]}
        valuePropName="targetKeys"
        className="selectContainer"
      >
        <Transfer
          showSearch
          dataSource={usersByDepartment as any}
          filterOption={handleUserSearch}
          rowKey={user => user.id}
          render={user => normalizeName(user)}
          disabled={!hasEditRights || !watchedDepartmentId}
        />
      </Form.Item>

      <div
        className={classNames(
          'ant-drawer-footer',
          styles['footer-button-wrapper'],
        )}
      >
        <Button
          htmlType="submit"
          type="primary"
          disabled={!hasEditRights || !watchedName || loading}
        >
          {saveLabel}
        </Button>
      </div>
    </Form>
  );
};
