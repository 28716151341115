import React, { DragEvent, ReactNode } from 'react';
import { Button } from 'antd';
import styles from './WidgetButton.module.less';
import { setDraggedWidgetType } from '../../features/dashboard/dashboardSlice';
import { WidgetTypes } from '../Widget/types/common';
import { useAppDispatch } from '../../store';

interface WidgetButtonProps {
  icon: ReactNode;
  name: WidgetTypes;
  title: string;
}

const WidgetButton: React.FC<WidgetButtonProps> = ({ icon, name, title }) => {
  const dispatch = useAppDispatch();

  const onDragStart = (e: DragEvent<HTMLButtonElement>) => {
    e.dataTransfer.setData('text/plain', '');
    dispatch(setDraggedWidgetType(name));
  };

  return (
    <Button
      icon={icon}
      type="text"
      size="large"
      title={title}
      draggable
      unselectable="on"
      onDragStart={onDragStart}
      className={styles.active}
    />
  );
};

export default WidgetButton;
