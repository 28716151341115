import React, { MouseEvent, memo } from 'react';
import cn from 'classnames';
import { EditOutlined } from '@ant-design/icons';
import Widget from '../Widget';
import { WidgetGrid } from '../../features/dashboard/types/dashboard';
import styles from './WidgetGridItem.module.less';
import DotsIcon from '../../assets/icons/DotsIcon';
import { useIntl } from 'react-intl';

interface WidgetGridItemProps {
  widgetGrid: WidgetGrid;
  editable: boolean;
  onSelect: (widgetGrid: WidgetGrid) => void;
  active: boolean;
  needToUpdate?: boolean;
  onEditBtnClick: (e: MouseEvent) => void;
  onChangeWidgetParameter: (widgetId: string, value: any) => void;
  onWidgetActionsClick?: () => void;
}

const WidgetGridItem: React.FC<WidgetGridItemProps> = ({
  widgetGrid,
  editable,
  onSelect,
  active,
  onEditBtnClick,
  onWidgetActionsClick,
  onChangeWidgetParameter,
}) => {
  const intl = useIntl();

  const handleWidgetGridClick = () => {
    if (!editable) {
      return;
    }

    onSelect(widgetGrid);
  };

  const widgetAction = intl.formatMessage({
    id: 'widget.WidgetAction',
    defaultMessage: 'Действия с виджетом',
  });

  const actions = !editable && (
    <div
      className={styles.dotsIcon}
      onClick={onWidgetActionsClick}
      title={widgetAction}
    >
      <DotsIcon width={18} height={18} />
    </div>
  );

  return (
    <div
      onClick={handleWidgetGridClick}
      className={cn(styles.container, { [styles.active]: active })}
    >
      <Widget
        widgetAccessoryRight={actions}
        widgetGrid={widgetGrid}
        isEditing={editable}
        onChangeParameter={onChangeWidgetParameter}
      />
      {active && (
        <div className={styles.editButton}>
          <EditOutlined style={{ color: 'white' }} onClick={onEditBtnClick} />
        </div>
      )}
    </div>
  );
};

const MemoizedWidgetGridItem = memo(WidgetGridItem);

export default MemoizedWidgetGridItem;
