import { AppThunk, RootState } from '../../store';
import API from '../../utils/API';
import { setError } from '../error/errorSlice';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Department, Profile, Role, User, UserSettings } from './types/user';
import {
  DefaultSortParams,
  Group,
  GroupListRequest,
  GroupUser,
} from './types/group';
import { DataItem } from './types/data';
import { RcFile } from 'antd/es/upload';
import { DashboardListItem } from '../dashboard/types/dashboard';
import publicApi from '../../utils/publicApi';

export type SettingsState = {
  userList: User[];
  userGroupList: Group[];
  roles: Role[];
  group: Group | null;
  loading: boolean;
  userSettings: UserSettings;
  settingsData: DataItem[];
  uploadingId: string;
  departments: Department[];
  usersByDepartment: Record<string, GroupUser[] | User[] | undefined>;
  dashboardsByDepartment: Record<string, DashboardListItem[] | undefined>;
};

const initialState: SettingsState = {
  userList: [],
  userGroupList: [],
  roles: [],
  group: null,
  loading: false,
  userSettings: {},
  settingsData: [],
  uploadingId: '',
  departments: [],
  usersByDepartment: {},
  dashboardsByDepartment: {},
};

const DEFAULT_PAGE = String(0);
const DEFAULT_LIMIT = String(50);
const DEFAULT_SORT_FIELD = 'name';
const DEFAULT_SORT_TYPE = 'ASC';

export const getDepartments = createAsyncThunk(
  'settings/getDepartments',
  async (_, { dispatch }) => {
    try {
      const response = await API.get<{ result: Department[] }>('department');

      return response?.data?.result;
    } catch (error: any) {
      dispatch(setError(error));

      return [];
    }
  },
);

export const getUsersByDepartments = createAsyncThunk(
  'settings/getUsersByDepartments',
  async (departmentId: string, { dispatch }) => {
    try {
      const response = await API.get<{
        result: GroupUser[] | User[];
      }>(`department/${departmentId}/users`);

      return { [departmentId]: response?.data?.result };
    } catch (error: any) {
      dispatch(setError(error));

      return { [departmentId]: undefined };
    }
  },
);

export const getDashboardsByDepartments = createAsyncThunk(
  'settings/getDashboardsByDepartments',
  async (departmentId: string, { dispatch }) => {
    try {
      const response = await API.get<{
        result: DashboardListItem[];
      }>(`department/${departmentId}/dashboards`);

      return { [departmentId]: response?.data?.result };
    } catch (error: any) {
      dispatch(setError(error));

      return { [departmentId]: undefined };
    }
  },
);

export const fetchGroupList = createAsyncThunk(
  'settings/getUserGroupList',
  async (
    params: undefined | Partial<GroupListRequest & { hasEditRights: boolean }>,
    { dispatch },
  ) => {
    try {
      dispatch(loading(true));

      const urlParams = new URLSearchParams({
        page: params?.page || DEFAULT_PAGE,
        size: params?.size || DEFAULT_LIMIT,
        sortField: params?.sortField || DEFAULT_SORT_FIELD,
        sortType: params?.sortType || DEFAULT_SORT_TYPE,
      });

      const response = await API.get<{
        result: {
          content: SettingsState['userGroupList'];
        };
      }>(`group?${urlParams}`);

      if (!params?.hasEditRights) {
        const uniqDepartments = [
          ...new Map(
            response.data?.result?.content?.map(i => [
              i.department.id,
              i.department,
            ]),
          ).values(),
        ];

        dispatch(receiveDepartments(uniqDepartments));
      }

      return response.data?.result?.content;
    } catch (error: any) {
      dispatch(setError(error));
      return [];
    } finally {
      dispatch(loading(false));
    }
  },
);

export const fetchUserList = createAsyncThunk(
  'settings/getUserList',
  async (params: undefined | Partial<DefaultSortParams>, { dispatch }) => {
    try {
      dispatch(loading(true));

      const urlParams = new URLSearchParams({
        page: params?.page || DEFAULT_PAGE,
        size: params?.size || DEFAULT_LIMIT,
      });

      const response = await API.get<{
        result: {
          content: SettingsState['userList'];
        };
      }>(`user/list?${urlParams}`);

      return response.data.result?.content;
    } catch (e: any) {
      dispatch(setError(e));
      return [];
    } finally {
      dispatch(loading(false));
    }
  },
);

export const fetchGroup = createAsyncThunk(
  'settings/fetchGroup',
  async (groupId: string, { dispatch }) => {
    try {
      const response = await API.get<{ result: Group | null }>(
        `group/${groupId}`,
      );

      return response.data.result;
    } catch (error: any) {
      dispatch(setError(error));

      return null;
    }
  },
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchGroupList.fulfilled, (state, action) => {
      state.userGroupList = action.payload;
    });

    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userList = action.payload;
    });

    builder.addCase(getDepartments.fulfilled, (state, action) => {
      state.departments = action.payload;
    });

    builder.addCase(fetchGroup.fulfilled, (state, action) => {
      state.group = action.payload;

      const departmentId = action.payload?.department.id;

      if (departmentId) {
        state.dashboardsByDepartment[departmentId] = action.payload?.dashboards;
        state.usersByDepartment[departmentId] = action.payload?.users;
      }
    });

    builder.addCase(getUsersByDepartments.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach(i => {
        state.usersByDepartment[i] = action.payload[i];
      });
    });

    builder.addCase(getDashboardsByDepartments.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach(i => {
        state.dashboardsByDepartment[i] = action.payload[i];
      });
    });
  },
  reducers: {
    loading: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetDepartments: state => {
      state.departments = [];
    },
    receiveDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload;
    },
    receiveRoleList: (state: SettingsState, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    resetGroup: (state: SettingsState) => {
      state.group = null;
    },
    receiveUserSettings: (
      state: SettingsState,
      action: PayloadAction<UserSettings | {}>,
    ) => {
      state.userSettings = action.payload;
    },
    receiveSettingsData: (
      state: SettingsState,
      { payload }: PayloadAction<DataItem[]>,
    ) => {
      state.settingsData = payload;
    },
    setUploadingId: (state, { payload }: PayloadAction<string>) => {
      state.uploadingId = payload;
    },
    resetUsersByDepartment(state) {
      state.usersByDepartment = {};
    },
    resetDashboardsByDepartment(state) {
      state.dashboardsByDepartment = {};
    },
  },
});

export const {
  loading,
  receiveRoleList,
  receiveUserSettings,
  receiveSettingsData,
  setUploadingId,
  resetDepartments,
  resetGroup,
  resetUsersByDepartment,
  resetDashboardsByDepartment,
  receiveDepartments,
} = settingsSlice.actions;

// middlewares

type ProfileWithoutId = Omit<Profile, 'id'>;

export const createUser =
  (
    user: Omit<User<ProfileWithoutId>, 'id'>,
    successCb?: (id: string) => void,
  ): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.post('user/create', user);
      if (typeof successCb === 'function') {
        successCb(data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const updateUser =
  (user: User, successCb?: (id: string) => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.put('user', user);
      if (typeof successCb === 'function') {
        successCb(data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const deleteUser =
  (userId: string, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      await API.delete(`user/${userId}`);
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const createGroup = createAsyncThunk(
  'settings/createGroup',
  async (group: Group, { dispatch }) => {
    try {
      const response = await API.post('group', group);

      return response.data;
    } catch (error: any) {
      dispatch(setError(error));

      return {};
    }
  },
);

export const updateGroup = createAsyncThunk(
  'settings/updateGroup',
  async (group: Group, { dispatch }) => {
    try {
      const response = await API.put('group', group);

      return response.data;
    } catch (error: any) {
      dispatch(setError(error));

      return {};
    }
  },
);

export const deleteGroup =
  (groupId: string, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      await API.delete(`group/${groupId}`);
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const fetchRoles = (): AppThunk => async dispatch => {
  try {
    const { data } = await API.get('role');
    dispatch(receiveRoleList(data?.result));
  } catch (e: any) {
    dispatch(setError(e));
  }
};

export const fetchUserSettings =
  (successCb?: () => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await publicApi.get(`config/user/data/manage`);
      dispatch(receiveUserSettings(data?.result));
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const fetchDataSettings = (): AppThunk => async dispatch => {
  try {
    const { data } = await API.get<{ result: DataItem[] }>('dataflow');
    dispatch(receiveSettingsData(data?.result));
  } catch (e: any) {
    dispatch(setError(e));
  }
};

export const uploadDataSettings =
  (dataFlowId: string, file?: RcFile, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(setUploadingId(dataFlowId));
    const formData = new FormData();
    formData.append('file', file || '');
    try {
      await API.post(`dataflow/${dataFlowId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        },
      });
      dispatch(setUploadingId(''));
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(setUploadingId(''));
    }
  };

// selectors

export const selectUserList = (state: RootState): User[] =>
  state.settings.userList;
export const selectGroupList = (state: RootState): Group[] =>
  state.settings.userGroupList;
export const selectRoleList = (state: RootState): Role[] =>
  state.settings.roles || [];
export const selectGroup = (state: RootState) => state.settings.group;
export const selectUserSettings = (state: RootState): UserSettings =>
  state.settings.userSettings || {};
export const selectSettingsData = (state: RootState): DataItem[] =>
  state.settings.settingsData;
export const selectUploadingId = (state: RootState): string =>
  state.settings.uploadingId;

export const departmentsSelector = (state: RootState) =>
  state.settings.departments;

export const dashboardsByDepartmentSelector = createSelector(
  (state: RootState) => state.settings.dashboardsByDepartment,
  (_: RootState, departmentId: string) => departmentId,
  (dashboardsByDepartment, departmentId) =>
    dashboardsByDepartment[departmentId] || [],
);

export const usersByDepartmentSelector = createSelector(
  (state: RootState) => state.settings.usersByDepartment,
  (_: RootState, departmentId: string) => departmentId,
  (usersByDepartment, departmentId) => usersByDepartment[departmentId],
);

export default settingsSlice.reducer;
