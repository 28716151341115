import React from 'react';
import { operatorOptions } from './utils';
import { Button } from 'antd';
import { uuid } from '../../../../../utils';
import { FormulaSet, Operators as OperatorsEnum } from './types';

interface OperatorsProps {
  selectedElements: FormulaSet[];
  setSelectedElements: (value: FormulaSet[]) => void;
}

const Operators = ({
  selectedElements,
  setSelectedElements,
}: OperatorsProps) => {
  const handleOperatorSelect = (value: OperatorsEnum, label: string) =>
    setSelectedElements([
      ...selectedElements,
      { key: value, label, id: uuid() },
    ]);

  return (
    <>
      {Object.keys(operatorOptions).map(operator => {
        const key = operator as keyof typeof operatorOptions;
        return (
          <Button
            onClick={() => handleOperatorSelect(key, operatorOptions[key])}
            key={operator}
          >
            {operatorOptions[key]}
          </Button>
        );
      })}
    </>
  );
};

export default Operators;
