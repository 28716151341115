import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from '../../SettingsUsers/styles.module.less';
import {
  addGroupLabel,
  editGroupLabel,
} from '../../SettingsUsers/AddUser/helper';
import { useSelector } from 'react-redux';
import {
  createGroup,
  departmentsSelector,
  fetchGroupList,
  getDepartments,
  resetDepartments,
  selectGroup,
  updateGroup,
} from '../../../settingsSlice';
import { GroupListItem } from '../../../types/group';
import { AddGroupForm } from './AddGroupForm';
import { FormWrapper } from './FormWrapper';
import { useAppDispatch } from '../../../../../store';

interface AddGroupDrawerProps {
  setShowDrawer: (drawerState: boolean) => void;
  showDrawer: boolean;
  isGroupEditing: boolean;
  openedGroup: GroupListItem | null;
  hasEditRights: boolean;
}

const AddGroupDrawer: React.FC<AddGroupDrawerProps> = ({
  setShowDrawer,
  showDrawer,
  isGroupEditing = false,
  openedGroup,
  hasEditRights,
}) => {
  const currentGroup = useSelector(selectGroup);
  const departments = useSelector(departmentsSelector);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (departments.length === 0 && hasEditRights) {
      dispatch(getDepartments());
    }

    return () => {
      dispatch(resetDepartments());
    };
  }, []);

  const onClose = () => {
    setShowDrawer(false);
  };

  const onFinish = async (values: any) => {
    setSubmitting(true);

    const normalizedValues = {
      ...values,
      name: values?.name?.trim(),
    };

    if (isGroupEditing) {
      const updatedGroup = { id: openedGroup?.id, ...normalizedValues }; // merge id's fields to form fields. form send values without id's (only described fields)

      try {
        await dispatch(updateGroup(updatedGroup));
        onClose();
        dispatch(fetchGroupList({ hasEditRights }));
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await dispatch(createGroup(normalizedValues));
        onClose();
        dispatch(fetchGroupList({ hasEditRights }));
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Drawer
      title={
        <span className={styles.drawerTitle}>
          {isGroupEditing ? editGroupLabel : addGroupLabel}
        </span>
      }
      destroyOnClose
      placement="left"
      width={480}
      closeIcon={<ArrowLeftOutlined />}
      onClose={onClose}
      open={showDrawer}
      footer={null}
    >
      <FormWrapper
        openedGroup={openedGroup}
        form={loading => (
          <AddGroupForm
            hasEditRights={hasEditRights}
            onFinish={onFinish}
            currentGroup={currentGroup}
            departments={departments}
            loading={submitting || loading}
          />
        )}
      />
    </Drawer>
  );
};

export default AddGroupDrawer;
