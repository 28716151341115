import React, { useEffect, useMemo } from 'react';
import { Button, Drawer, Form } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from '../styles.module.less';
import { addAccountLabel, editAccountLabel, saveLabel } from './helper';
import { Sources, User } from '../../../types/user';
import AddUserForm from './AddUserForm';
import { selectUserSettings } from '../../../settingsSlice';
import { useAppSelector } from '../../../../../store';

interface AddUserDrawerProps {
  setShowDrawer: (drawerState: boolean) => void;
  setOpenedUser: (openedUser: User | null) => void;
  showDrawer: boolean;
  isUserEditing: boolean;
  openedUser: User | null;
  onSaveSuccess?: () => void;
  isSettingsPlace?: boolean;
  hasEditRights: boolean;
}

const AddUserDrawer: React.FC<AddUserDrawerProps> = ({
  setShowDrawer,
  showDrawer,
  isUserEditing = false,
  openedUser,
  setOpenedUser,
  onSaveSuccess,
  hasEditRights,
  isSettingsPlace = false,
}) => {
  const [form] = Form.useForm();
  const userSettings = useAppSelector(selectUserSettings);

  const disabledByIntegration = useMemo(() => {
    return Object.values(userSettings).every(i => i.source !== Sources.HOME);
  }, [userSettings]);

  useEffect(() => form.resetFields(), [form, openedUser]);

  const onClose = () => {
    setShowDrawer(false);
    setOpenedUser(null);
    form.resetFields();
  };

  const handleClick = () => form.submit();

  return (
    <>
      <Drawer
        title={
          <span className={styles.drawerTitle}>
            {isUserEditing ? editAccountLabel : addAccountLabel}
          </span>
        }
        closeIcon={<ArrowLeftOutlined />}
        placement="left"
        width={550}
        onClose={onClose}
        open={showDrawer}
        destroyOnClose
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button
              onClick={handleClick}
              htmlType="submit"
              type="primary"
              disabled={disabledByIntegration || !hasEditRights}
            >
              {saveLabel}
            </Button>
          </div>
        }
      >
        <AddUserForm
          form={form}
          openedUser={openedUser}
          onClose={onClose}
          onSaveSuccess={onSaveSuccess}
          isUserEditing={isUserEditing}
          hasEditRights={hasEditRights}
          isSettingsPlace={isSettingsPlace}
        />
      </Drawer>
    </>
  );
};

export default AddUserDrawer;
