import { Permissions } from '../features/settings/types/user';

export const hasAccess = (
  requiredRights: Permissions[],
  userRights: Permissions[],
  every: boolean | undefined = false,
) => {
  if (every) {
    return requiredRights.every(right => userRights.includes(right));
  }

  return requiredRights.some(right => userRights.includes(right));
};
