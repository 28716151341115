import { SelectProps } from 'antd';
import moment from 'moment';

import { intl } from '../../../../../utils/intl';

import { Formatters } from './types';
import mathInstance from '../mathInstance';

const PERCENT = '%';
export const DEFAULT_ROUND = 2;

const percentLabel = intl.formatMessage({
  id: 'TableWidgetFormatter.Percent',
  defaultMessage: 'Проценты',
});
const roundLabel = intl.formatMessage({
  id: 'TableWidgetFormatter.Round',
  defaultMessage: 'Округление',
});
const dateLabel = intl.formatMessage({
  id: 'TableWidgetFormatter.Date',
  defaultMessage: 'Дата в dd:mm:yyyy',
});
const timeLabel = intl.formatMessage({
  id: 'TableWidgetFormatter.Time',
  defaultMessage: 'Время в hh:mm:ss',
});

export const formattersOptions: SelectProps['options'] = [
  {
    value: Formatters.PERCENT,
    label: percentLabel,
  },
  {
    value: Formatters.DATE_FORMAT,
    label: dateLabel,
  },
  {
    value: Formatters.ROUND,
    label: roundLabel,
  },
];

const formatterSwitcher = ({
  value,
  rule,
  options,
}: {
  value: any;
  rule: Formatters;
  options: Record<string, any>;
}) => {
  switch (rule) {
    case Formatters.PERCENT: {
      const num = mathInstance.format(
        mathInstance.multiply(Number(value), 100),
        { precision: 14 },
      );

      return num + PERCENT;
    }

    case Formatters.TIME_FORMAT:
      return moment(String(value), 'hh:mm:ss').format('hh:mm:ss').toString();

    case Formatters.ROUND: {
      const hasEndedPercent = String(value).endsWith(PERCENT);

      if (hasEndedPercent) {
        const valueWithoutPercent = String(value).slice(0, -1);

        return `${Number(valueWithoutPercent).toFixed(
          options?.round || DEFAULT_ROUND,
        )}${PERCENT}`;
      }

      return Number(value).toFixed(options?.round || DEFAULT_ROUND);
    }

    case Formatters.DATE_FORMAT:
      return moment(String(value), 'YYYY-MM-DD')
        .format('DD-MM-YYYY')
        .toString();

    default:
      return value;
  }
};

export const applyFormatters = (
  value: any,
  formatters: {
    rules: Formatters[];
    options: Record<string, any>;
  },
) => {
  let result = value;

  const rules = formatters?.rules || [];

  for (const rule of rules) {
    result = formatterSwitcher({
      value: result,
      rule,
      options: formatters.options,
    });
  }

  return result;
};
