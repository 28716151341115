import React from 'react';
import styles from './TableWidget.module.less';
import { Select } from 'antd';
import { useIntl } from 'react-intl';

interface RowsSelectProps {
  rows: string[];
  onChange: (value: string[]) => void;
  options: { label: string; value: string }[];
}

const RowsSelect = ({ rows, onChange, options }: RowsSelectProps) => {
  const intl = useIntl();
  const rowsTitle = intl.formatMessage({
    id: 'widget.Rows',
    defaultMessage: 'Строки',
  });

  return (
    <>
      <h4>{rowsTitle}</h4>
      <Select
        mode="multiple"
        size="large"
        placeholder={rowsTitle}
        value={rows}
        onChange={onChange}
        className={styles.select}
        options={options}
      />
    </>
  );
};

export default RowsSelect;
