export enum LocaleEnum {
  DE = 'DE',
  RU = 'RU',
  EN = 'EN',
}

export type ReceivedConfigType = {
  localisation: LocaleEnum[];
  passwordMinLength: number;
  auth: {
    url: string;
    realm: string;
    clientId: string;
    scopes: string;
  };
};

export type GlobalConfig = {
  backend?: string;
  receivedConfig: ReceivedConfigType;
};

declare global {
  interface Window {
    config: GlobalConfig;
  }
}

const backEndForConfigJS = window.config?.backend;

const GLOBAL_API_PREFIX = 'api/';

const DEFAULT_BACKEND =
  process.env.NODE_ENV === 'production'
    ? backEndForConfigJS || GLOBAL_API_PREFIX
    : 'https://dash-dev.dc.oswfm.ru/' + GLOBAL_API_PREFIX;

export const PRIVATE_BASE_URL = DEFAULT_BACKEND + 'private/';
export const PUBLIC_BASE_URL = DEFAULT_BACKEND + 'public/';
