import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { filterByRights } from './menu';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../../features/user/userSlice';

const ManagerMenu: React.FC = () => {
  const currentUserGrants = useSelector(selectCurrentUserGrants);
  const { path } = useRouteMatch();

  const items = useMemo(() => {
    const permitted = filterByRights(currentUserGrants);

    return permitted.map(i => ({
      key: i.name,
      icon: i.icon,

      label: <Link to={i.link}>{i.label}</Link>,
    }));
  }, [currentUserGrants]);

  const currentPath = useMemo(() => {
    if (path) {
      return path.split('/');
    }

    return [];
  }, [path]);

  return <Menu selectedKeys={currentPath} items={items} />;
};

export default ManagerMenu;
