import React, { useEffect, useMemo } from 'react';
import { Tabs, TabsProps } from 'antd';
import { FormattedMessage } from 'react-intl';
import ManagerMainLayout from '../../../../components/layout/ManagerMainLayout';
import SettingsUsers from '../SettingsUsers';
import SettingsGroups from '../SettingsGroups';
import { useSelector } from 'react-redux';
import { fetchDashboardList } from '../../../dashboard/dashboardSlice';
import { hasAccess } from '../../../../utils/rights';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { Permissions } from '../../types/user';
import SettingsData from '../SettingsData';
import { DASHBOARD_PERMISSIONS } from '../../../../components/layout/ManagerMenu/menu';
import { useAppDispatch } from '../../../../store';

const USERS_PERMISSIONS = [Permissions.USER_READ, Permissions.USER_EDIT];
const GROUPS_PERMISSIONS = [Permissions.GROUP_READ, Permissions.GROUP_EDIT];
const DATA_PERMISSIONS = [
  Permissions.DATA_LOAD,
  Permissions.DATA_ETL,
  Permissions.DATA_EDIT,
];

const Settings = () => {
  const userGrants = useSelector(selectCurrentUserGrants);

  const hasUsersTabAccess = useMemo(
    () => hasAccess(USERS_PERMISSIONS, userGrants),
    [userGrants],
  );
  const hasGroupsTabAccess = useMemo(
    () => hasAccess(GROUPS_PERMISSIONS, userGrants),
    [userGrants],
  );
  const hasDataTabAccess = useMemo(
    () => hasAccess(DATA_PERMISSIONS, userGrants),
    [userGrants],
  );
  const hasSearchRights = useMemo(
    () => hasAccess(DASHBOARD_PERMISSIONS, userGrants),
    [userGrants],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDashboardList());
  }, [dispatch]);

  const tabs = useMemo(() => {
    const result: TabsProps['items'] = [];

    if (hasUsersTabAccess) {
      result.push({
        key: 'users',
        label: (
          <FormattedMessage
            id="settings.Accounts"
            defaultMessage="Учетные записи"
          />
        ),
        children: <SettingsUsers />,
      });
    }

    if (hasGroupsTabAccess) {
      result.push({
        key: 'groups',
        label: (
          <FormattedMessage id="settings.Groups" defaultMessage="Группы" />
        ),
        children: <SettingsGroups />,
      });
    }

    if (hasDataTabAccess) {
      result.push({
        key: 'data',
        label: (
          <FormattedMessage
            id="settings.Data"
            defaultMessage="Работа с данными"
          />
        ),
        children: <SettingsData />,
      });
    }

    return result;
  }, [userGrants]);

  const defaultKey = useMemo(() => {
    if (hasUsersTabAccess) {
      return 'users';
    }

    if (hasGroupsTabAccess) {
      return 'groups';
    }

    if (hasDataTabAccess) {
      return 'data';
    }

    return '';
  }, [userGrants]);

  return (
    <ManagerMainLayout hasSearchRights={hasSearchRights}>
      <Tabs defaultActiveKey={defaultKey} items={tabs} />
    </ManagerMainLayout>
  );
};

export default Settings;
