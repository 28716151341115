import React, { useEffect, useState } from 'react';
import { GroupListItem } from '../../../types/group';
import {
  fetchGroup,
  resetDashboardsByDepartment,
  resetGroup,
  resetUsersByDepartment,
} from '../../../settingsSlice';
import { Spin } from 'antd';
import styles from '../../SettingsUsers/styles.module.less';
import { useAppDispatch } from '../../../../../store';

type FormWrapperProps = {
  openedGroup: GroupListItem | null;
  form: (loading: boolean) => JSX.Element;
};

export const FormWrapper = (props: FormWrapperProps) => {
  const { openedGroup, form } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (openedGroup?.id) {
      setLoading(true);

      dispatch(fetchGroup(openedGroup.id)).then(() => {
        setLoading(false);
      });
    }

    return () => {
      dispatch(resetGroup());
      dispatch(resetDashboardsByDepartment());
      dispatch(resetUsersByDepartment());
    };
  }, []);

  if (loading) {
    return (
      <div className={styles.spinWrapper}>
        <Spin />
      </div>
    );
  }

  return form(loading);
};
