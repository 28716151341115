import axios from 'axios';
import tokenService from './tokenService';
import { updateKcToken } from '../hooks/useKeycloak';
import { createTabId, getTabId } from './tabId';
import { PRIVATE_BASE_URL } from '../config';

// Set config defaults when creating the instance
const API = axios.create({
  baseURL: PRIVATE_BASE_URL,
  responseType: 'json',
  headers: { 'Content-Type': 'application/json' },
});

// Alter defaults after instance has been created
// API.defaults.headers.common['Accept'] = '*/*';
// API.defaults.headers.common['Sec-Fetch-Site'] = 'cross-site';
API.interceptors.request.use(config => {
  const token = tokenService.getAccessToken();
  createTabId();
  const tabId = getTabId();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers.tabId = tabId;

  return config;
});

export default API;

// Response interceptor for API calls
API.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken: string = (await updateKcToken()) || '';
      API.defaults.headers.common.Authorization = 'Bearer ' + accessToken;

      return API(originalRequest);
    }
    return Promise.reject(error);
  },
);
