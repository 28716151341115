import {
  AggregationTypes,
  Column,
  DataItem,
  Periods,
  PivotGroupConf,
  TotalColumns,
  ValuesPlace,
} from '../../types/table';
import _ from 'lodash';
import moment from 'moment/moment';
import {
  checkFormat,
  checkKey,
  createPivotColumn,
  formatDataItem,
  periodFormats,
  ROW_WITHOUT_COLUMN,
  tableDateFormat,
  TOTAL_COLUMN_KEY,
} from './helpers';
import { aggregationDropdownItems, calculateTotals } from './aggregators';
import { intl } from '../../../../utils/intl';
import { calculateByFormula } from './Calculation/utils';

const getDateFormat = (date: string, period: Periods) =>
  moment(date, tableDateFormat).format(periodFormats[period]);

export const totalLabel = intl.formatMessage({
  id: 'Common.Total',
  defaultMessage: 'Итого',
});

type groupedByColumns = DataItem[] | Record<string, DataItem[]>;

const groupRowsByValues = (
  data: DataItem[],
  valueKeys: PivotGroupConf['values'],
  valueTotalTypes: PivotGroupConf['valueTotalTypes'],
  formulas: PivotGroupConf['formulas'],
) =>
  valueKeys.reduce<DataItem[]>((acc, valueKey) => {
    const calculated = calculateTotals(data, valueTotalTypes);
    const calculatedByFormula = calculateByFormula(data, formulas);
    const aggregationType = aggregationDropdownItems[valueTotalTypes[valueKey]]
      ? `(${aggregationDropdownItems[valueTotalTypes[valueKey]]})`
      : '';
    const formulaTitle = formulas.find(
      formula => formula.id === valueKey,
    )?.name;
    const title = formulaTitle || valueKey;
    const row = {
      [ROW_WITHOUT_COLUMN]: `${title} ${aggregationType}`,
      [valueKey]: calculated[valueKey],
      ...(formulaTitle && calculatedByFormula),
    };
    return { ...acc, [valueKey]: [row] };
  }, []);

const groupByDatesRecursive = (
  columns: string[],
  data: DataItem[],
  groupBy: PivotGroupConf['groupBy'],
  periodIndex: number = 0,
  colIndex: number,
): groupedByColumns => {
  const col = columns[colIndex];
  const period = groupBy[col][periodIndex];
  if (period) {
    const grouped = _.groupBy(data, row => {
      const date = checkFormat(row[col], tableDateFormat)
        ? getDateFormat(row[col], period)
        : row[col];
      return period ? date : row[col];
    });
    return Object.keys(grouped).reduce(
      (acc, key) => ({
        ...acc,
        [key]: groupByColumnsRecursive(
          columns,
          grouped[key],
          colIndex,
          groupBy,
          periodIndex + 1,
        ),
      }),
      {},
    );
  }
  return groupByColumnsRecursive(columns, data, colIndex + 1, groupBy);
};

const groupByColumnsRecursive = (
  columns: string[],
  data: DataItem[],
  i: number = 0,
  groupBy: PivotGroupConf['groupBy'],
  periodIndex: number = 0,
): groupedByColumns => {
  const col = columns[i];
  const hasKey = checkKey(col, data);
  const period = groupBy?.[col]?.[periodIndex];
  if (period && hasKey) {
    return groupByDatesRecursive(columns, data, groupBy, periodIndex, i);
  } else if (hasKey) {
    const grouped = _.groupBy(data, row => row[col]);
    return Object.keys(grouped).reduce(
      (acc, key) => ({
        ...acc,
        [key]: groupByColumnsRecursive(columns, grouped[key], i + 1, groupBy),
      }),
      {},
    );
  }
  return data;
};

const generateColumns = (
  data: groupedByColumns = [],
  values: string[],
  prefix: string = '',
  formulas: PivotGroupConf['formulas'],
  valueTotalTypes: Record<string, AggregationTypes>,
  shouldGroupRowsByValues: boolean,
): { columns: Column[]; totalColumns: TotalColumns } => {
  let totalColumns = {};
  const getColumns = (
    data: groupedByColumns = [],
    values: string[],
    prefix: string = '',
    formulas: PivotGroupConf['formulas'],
  ): Column[] => {
    const isArray = Array.isArray(data);
    if (isArray) {
      return !shouldGroupRowsByValues
        ? values.map(el => {
            const formulaTitle = formulas.find(
              formula => formula.id === el,
            )?.name;
            const aggregationType = aggregationDropdownItems[
              valueTotalTypes[el]
            ]
              ? `(${aggregationDropdownItems[valueTotalTypes[el]]})`
              : '';
            const title = formulaTitle || `${el} ${aggregationType}`;
            return createPivotColumn(`${prefix}${el}`, title);
          })
        : [];
    }
    return Object.keys(data).reduce<Column[]>((acc, key) => {
      const column = `${prefix}${key}`;
      const isLastChild = Array.isArray(data[key]);
      const children = getColumns(data[key], values, column, formulas);
      if (!isLastChild && !shouldGroupRowsByValues) {
        const totals = values.map(el => {
          const formulaTitle = formulas.find(
            formula => formula.id === el,
          )?.name;
          const aggregationType = aggregationDropdownItems[valueTotalTypes[el]]
            ? `(${aggregationDropdownItems[valueTotalTypes[el]]})`
            : '';
          const title = formulaTitle || `${el} ${aggregationType}`;
          return createPivotColumn(`${column}${TOTAL_COLUMN_KEY}${el}`, title);
        });
        totalColumns = { ...totalColumns, [column]: totals };
      }
      return [...acc, createPivotColumn(column, key, !isLastChild, children)];
    }, []);
  };

  return {
    columns: getColumns(data, values, prefix, formulas),
    totalColumns,
  };
};

const getData = (data: groupedByColumns): DataItem[] => {
  if (Array.isArray(data)) {
    return data;
  }
  return Object.keys(data).reduce<DataItem[]>(
    (acc, key) => [
      ...acc,
      ...(Array.isArray(data[key]) ? data[key] : getData(data[key])),
    ],
    [],
  );
};

const groupByRows = (
  data: groupedByColumns = [],
  rows: string[],
  parentKey: string = '',
  formulas: PivotGroupConf['formulas'],
  shouldGroupRowsByValues: boolean,
  values: string[],
  valueTotalTypes: PivotGroupConf['valueTotalTypes'],
): Record<string, groupedByColumns> => {
  if (Array.isArray(data)) {
    const groupedByRows = shouldGroupRowsByValues
      ? groupRowsByValues(data, values, valueTotalTypes, formulas)
      : groupByColumnsRecursive(rows, data, 0, {});

    return { [parentKey]: groupedByRows };
  }
  return Object.keys(data).reduce((acc, key) => {
    const prefix = `${parentKey}${key}`;
    const currentData = getData(data[key]);
    const groupedByRows = shouldGroupRowsByValues
      ? groupRowsByValues(currentData, values, valueTotalTypes, formulas)
      : groupByColumnsRecursive(rows, currentData, 0, {});

    return {
      ...acc,
      ...groupByRows(
        data[key],
        rows,
        prefix,
        formulas,
        shouldGroupRowsByValues,
        values,
        valueTotalTypes,
      ),
      [prefix]: groupedByRows,
    };
  }, {});
};

const changeKeys = (
  dataItem: DataItem,
  prefix: string,
  valuesInRows: boolean = false,
): DataItem =>
  Object.keys(dataItem).reduce((acc, key) => {
    const isRowWithoutColumn = key === ROW_WITHOUT_COLUMN;
    if (isRowWithoutColumn) {
      return { ...acc, [key]: dataItem[key] };
    }
    const newKey = valuesInRows ? prefix : `${prefix}${key}`;
    return {
      ...acc,
      [newKey]: dataItem[key],
      [`${prefix}${TOTAL_COLUMN_KEY}${key}`]: dataItem[key],
    };
  }, {});

const mergeByRow = (data: DataItem[]): DataItem[] => {
  const grouped = _.groupBy(data, dataItem => dataItem[ROW_WITHOUT_COLUMN]);
  return Object.values(grouped).reduce(
    (acc, values) => [
      ...acc,
      {
        ...values.reduce(
          (valuesAcc, el) => ({
            ...valuesAcc,
            ...el,
            ...(el?.children && {
              children: mergeByRow([
                ...(valuesAcc?.children || []),
                ...el.children,
              ]),
            }),
          }),
          {},
        ),
      },
    ],
    [],
  );
};

const calc = (
  data: Record<string, groupedByColumns>,
  formulas: PivotGroupConf['formulas'],
  valueTotalTypes: PivotGroupConf['valueTotalTypes'],
  formatters: PivotGroupConf['formatters'],
) => {
  const calcRowData = (
    rowData: groupedByColumns,
    prefix: string,
  ): DataItem[] => {
    if (Array.isArray(rowData)) {
      const calculatedByFormula = calculateByFormula(rowData, formulas);
      const calculatedTotals = calculateTotals(rowData, valueTotalTypes);
      const formatted = formatDataItem(
        {
          ...calculatedTotals,
          ...calculatedByFormula,
        },
        formatters,
      );
      return [changeKeys(formatted, prefix)];
    }
    return Object.keys(rowData).reduce<DataItem[]>((acc, key) => {
      const currentData = getData(rowData[key]);
      const calcResult = calculateTotals(currentData, valueTotalTypes);
      const calculatedByFormula = calculateByFormula(currentData, formulas);

      const formatted = formatDataItem(
        {
          ...calcResult,
          ...calculatedByFormula,
        },
        formatters,
      );

      const isArray = Array.isArray(rowData[key]);

      const changedKeys = changeKeys(formatted, prefix);

      return [
        ...acc,
        {
          ...changedKeys,
          ...(!isArray && { children: calcRowData(rowData[key], prefix) }),
          [ROW_WITHOUT_COLUMN]: key,
          key,
        },
      ];
    }, []);
  };

  const result = Object.entries(data).reduce<DataItem[]>(
    (acc, [prefix, value]) => {
      const rowData = calcRowData(value, prefix);
      const currentData = getData(value);
      const calculatedTotals = calculateTotals(currentData, valueTotalTypes);
      const calculatedByFormula = calculateByFormula(currentData, formulas);

      const totalCalcResult = formatDataItem(
        {
          ...calculatedTotals,
          ...calculatedByFormula,
        },
        formatters,
      );

      const total = {
        [ROW_WITHOUT_COLUMN]: totalLabel,
        ...changeKeys(totalCalcResult, prefix),
      };

      return [...acc, ...rowData, total];
    },
    [],
  );

  return mergeByRow(result);
};

const correctKeys = (
  data: Record<string, groupedByColumns>,
  formatters: PivotGroupConf['formatters'],
) => {
  const calcRowData = (
    rowData: groupedByColumns,
    prefix: string,
  ): DataItem[] => {
    if (Array.isArray(rowData)) {
      const formatted = formatDataItem(rowData[0], formatters);
      return [changeKeys(formatted, prefix, true)];
    }
    return Object.keys(rowData).reduce<DataItem[]>((acc, key) => {
      const currentData = rowData[key][0];
      const formatted = formatDataItem(currentData, formatters);
      const changedKeys = changeKeys(formatted, prefix, true);

      return [
        ...acc,
        {
          ...changedKeys,
          key,
        },
      ];
    }, []);
  };

  const result = Object.entries(data).reduce<DataItem[]>(
    (acc, [prefix, value]) => {
      const rowData = calcRowData(value, prefix);

      return [...acc, ...rowData];
    },
    [],
  );

  return mergeByRow(result);
};

const group = (
  columns: string[],
  rows: string[],
  values: string[],
  data: DataItem[],
  groupBy: PivotGroupConf['groupBy'],
  formulas: PivotGroupConf['formulas'],
  valueTotalTypes: PivotGroupConf['valueTotalTypes'],
  valuesPlace: PivotGroupConf['valuesPlace'],
  formatters: PivotGroupConf['formatters'],
) => {
  const shouldGroupRowsByValues =
    valuesPlace === ValuesPlace.ROWS && !rows.length;
  const result = groupByColumnsRecursive(columns, data, 0, groupBy);
  const resultRows = groupByRows(
    result,
    rows,
    '',
    formulas,
    shouldGroupRowsByValues,
    values,
    valueTotalTypes,
  );

  const calcResult = shouldGroupRowsByValues
    ? correctKeys(resultRows, formatters)
    : calc(resultRows, formulas, valueTotalTypes, formatters);

  const groupedColumns = generateColumns(
    result,
    values,
    '',
    formulas,
    valueTotalTypes,
    shouldGroupRowsByValues,
  );

  return {
    data: calcResult,
    columns: groupedColumns.columns,
    totalColumns: groupedColumns.totalColumns,
  };
};

export const pivotDataFormatter = (
  data: DataItem[],
  config: PivotGroupConf,
) => {
  const columnForRow: Column = {
    title: '',
    dataIndex: ROW_WITHOUT_COLUMN,
    key: ROW_WITHOUT_COLUMN,
  };

  const {
    columns,
    values,
    rows,
    groupBy,
    valueTotalTypes,
    formulas,
    showRowTotal,
    valuesPlace,
    formatters,
  } = config;

  const grouped = group(
    columns,
    rows,
    values,
    data,
    groupBy,
    formulas,
    valueTotalTypes,
    valuesPlace,
    formatters,
  );

  const showData = !!rows.length || !!values.length || !!formulas.length;

  const filteredRowTotal = showRowTotal
    ? grouped.data
    : grouped.data.filter(el => el[ROW_WITHOUT_COLUMN] !== totalLabel);

  return {
    data: showData ? filteredRowTotal : [],
    columns: [columnForRow, ...grouped.columns],
    totalColumns: grouped.totalColumns,
  };
};
