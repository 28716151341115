import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuildInfoType } from './types';
import { AppThunk, RootState } from '../../store';
import { setError } from '../../features/error/errorSlice';
import publicApi from '../../utils/publicApi';

interface LayoutState {
  buildInfo: BuildInfoType | null;
}

const initialState: LayoutState = {
  buildInfo: null,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setBuildInfo: (state, { payload }: PayloadAction<BuildInfoType>) => {
      state.buildInfo = payload;
    },
  },
});

export const { setBuildInfo } = layoutSlice.actions;

export const fetchBuildInfo = (): AppThunk => async dispatch => {
  try {
    const response = await publicApi.get<{ result: BuildInfoType }>(
      'build/info',
    );
    dispatch(setBuildInfo(response.data.result));
  } catch (e: any) {
    dispatch(setError(e));
  }
};

export const buildInfoSelector = (state: RootState): LayoutState['buildInfo'] =>
  state.layout.buildInfo;

export default layoutSlice.reducer;
