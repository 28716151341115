import React, { memo, useMemo } from 'react';
import { DatePicker, TimePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { DateWidgetConfig } from '../../types/dateTime';
import { widgetDateFormat } from '../../../../utils/dateFormats';

interface DateTimeWidgetProps {
  config: DateWidgetConfig;
  onChange: (data: string) => void;
  parameter: string | string[];
}

const getDate = (params: DateTimeWidgetProps['parameter']): Moment => {
  const currentDate = moment();

  if (Array.isArray(params)) {
    const [first] = params;

    return first ? moment(first) : currentDate;
  }

  return params ? moment(params) : currentDate;
};

const DateTimeWidget: React.FC<DateTimeWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const handleChange = (value: Moment | null) => {
    onChange(value ? value.toString() : '');
  };

  const { label, padding } = config;

  const initialDate = useMemo(() => {
    return getDate(parameter);
  }, [parameter]);

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <DatePicker
          size="large"
          format={widgetDateFormat}
          defaultValue={initialDate}
          onChange={handleChange}
        />
        <TimePicker
          size="large"
          defaultValue={initialDate}
          onChange={handleChange}
        />
      </Space>
    </div>
  );
};

const MemoizedDateTimeWidget = memo(DateTimeWidget);

export default MemoizedDateTimeWidget;
