import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, MenuProps, Select, SelectProps, Tag } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  VerticalAlignMiddleOutlined,
} from '@ant-design/icons';

import {
  PivotGroupConf,
  AggregationTypes,
  DataTypes,
} from '../../../types/table';
import styles from '../TableWidget.module.less';

import { FormatBy } from './FormatBy';
import { Formatters } from './types';
import { getAggregationTypes } from '../aggregators';

type TagRender = SelectProps['tagRender'];

type ValuesSelectProps = {
  values: PivotGroupConf['values'];
  handleChangeValues: (value: string[]) => void;
  formatters: PivotGroupConf['formatters'];
  formulas: PivotGroupConf['formulas'];
  dataTypes: Record<string, DataTypes>;
  handleChangeFormatter: (
    selectedColumn: string,
    value: {
      rules: Formatters[];
      options: Record<string, any>;
    },
  ) => void;
  handleRemoveFormatter: (selectedColumn: string) => void;
  handleRemoveFormula: (id: string) => void;
  onValueTotalTypeChange: (row: string, type: AggregationTypes) => void;
  valueTotalTypes: Record<string, AggregationTypes>;
} & Pick<SelectProps, 'options'>;

export const ValuesSelect = (props: ValuesSelectProps) => {
  const {
    values,
    options,
    formulas,
    dataTypes,
    handleChangeValues,
    formatters,
    handleChangeFormatter,
    handleRemoveFormatter,
    valueTotalTypes,
    onValueTotalTypeChange,
    handleRemoveFormula,
  } = props;

  const formulaOptions = formulas.map(el => ({ label: el.name, value: el.id }));
  const formulaIds = formulas.map(({ id }) => id);
  const allOptions = [...(options || []), ...formulaOptions];

  const intl = useIntl();
  const [selectedColumn, setSelectedColumn] =
    useState<keyof PivotGroupConf['formatters']>('');

  const valuesTitle = intl.formatMessage({
    id: 'widget.Values',
    defaultMessage: 'Значения',
  });

  const onCloseModal = () => setSelectedColumn('');
  const onOpenModal = (value: string) => setSelectedColumn(value);

  const formatterValue = useMemo(() => {
    if (selectedColumn === '') {
      return {
        rules: [],
        options: {},
      };
    }

    return formatters?.[selectedColumn];
  }, [formatters, selectedColumn]);

  const tagRender: TagRender = props => {
    const { label, closable, onClose, value } = props;

    const dataType = dataTypes[value];
    const aggregationTypes = getAggregationTypes(dataType);
    const selectedType =
      valueTotalTypes[value] || Object.keys(aggregationTypes)[0];
    const items = (Object.keys(aggregationTypes) as AggregationTypes[]).map(
      key => ({
        key,
        label: aggregationTypes[key as keyof typeof aggregationTypes],
        ...(selectedType === key && { icon: <CheckOutlined /> }),
      }),
    );

    const handleMenuClick: MenuProps['onClick'] = e =>
      onValueTotalTypeChange(value, e.key as AggregationTypes);

    const onPreventMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const onCLick = () => onOpenModal(value);

    const closeHandler = (e: any) => {
      handleRemoveFormatter(value);
      onClose(e);
    };

    const onRemoveFormulaClick = () => {
      const filtered = values.filter(el => el !== value);
      handleChangeValues(filtered);
      handleRemoveFormula(value);
    };

    return (
      <Tag
        className="ant-select-selection-item"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={closeHandler}
        style={{ fontSize: 16 }}
      >
        {label}
        <span onClick={onCLick} className={styles.groupIcon}>
          <VerticalAlignMiddleOutlined rotate={90} />
        </span>
        {formulaIds.includes(value) ? (
          <span onClick={onRemoveFormulaClick} className={styles.groupIcon}>
            <DeleteOutlined />
          </span>
        ) : (
          <span className={styles.groupIcon}>
            <Dropdown
              trigger={['click']}
              menu={{ items, onClick: handleMenuClick }}
            >
              <DownOutlined />
            </Dropdown>
          </span>
        )}
      </Tag>
    );
  };

  return (
    <React.Fragment>
      <h4>{valuesTitle}</h4>
      <Select
        mode="multiple"
        size="large"
        placeholder={valuesTitle}
        value={values}
        onChange={handleChangeValues}
        className={styles.select}
        options={allOptions}
        tagRender={tagRender}
      />
      <FormatBy
        open={Boolean(selectedColumn)}
        onClose={onCloseModal}
        value={formatterValue}
        selectedColumn={selectedColumn}
        onChange={handleChangeFormatter}
      />
    </React.Fragment>
  );
};
