import {
  departmentLabel,
  userGroupNameLabel,
} from '../SettingsUsers/AddUser/helper';
import { sortAlphabetically } from '../../../../utils';
import styles from '../SettingsUsers/styles.module.less';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { Group } from '../../types/group';
import { intl } from '../../../../utils/intl';
import { Popconfirm, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const deleteGroupTitle = intl.formatMessage({
  id: 'Settings.delete',
  defaultMessage: 'Удалить группу',
});

const { Text } = Typography;

export const getColumns = (
  onDeleteClick: (userId: string) => void,
  hasEditRights: boolean,
) => {
  return [
    {
      title: userGroupNameLabel,
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: any, b: any) => sortAlphabetically(a.name, b.name),
      },
      render: (text: string, group: Group) => {
        const stopPropagation = (e: any) => {
          e?.stopPropagation();
        };

        const onDeleteUser = (e: any) => {
          stopPropagation(e);

          onDeleteClick(group.id);
        };

        return (
          <div className={styles.fullNameContainer}>
            {hasEditRights && (
              <Popconfirm
                title={
                  <FormattedMessage
                    id="GroupDeleteConfirmation.Question"
                    defaultMessage="Вы действительно хотите удалить {groupName}?"
                    values={{ groupName: group?.name || group?.displayName }}
                  />
                }
                onConfirm={onDeleteUser}
                onCancel={stopPropagation}
              >
                <span
                  title={deleteGroupTitle}
                  className={styles.deleteBtn}
                  onClick={stopPropagation}
                >
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            )}
            <Text ellipsis={{ tooltip: text }}>{text}</Text>
          </div>
        );
      },
    },
    {
      title: departmentLabel,
      dataIndex: ['department', 'name'],
      ellipsis: true,
      textWrap: 'word-break',
      key: 'department.name',
      sorter: {
        compare: (a: any, b: any) =>
          sortAlphabetically(a.department.name, b.department.name),
      },
    },
  ];
};
