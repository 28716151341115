import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, List, Modal, Tag } from 'antd';
import styles from './Calculation.module.less';
import { aggregationDropdownItems, getAggregationTypes } from '../aggregators';
import {
  DataItem,
  Formula,
  FormulaPlace,
  AggregationTypes,
} from '../../../types/table';
import { CalcItem, FormulaSet } from './types';
import ListItem from './ListItem';
import Operators from './Operators';
import { uuid } from '../../../../../utils';
import { checkIsFormulaValid } from './utils';
import { checkDataTypes } from '../helpers';

interface CalculationProps {
  isOpen: boolean;
  onClose: () => void;
  data: DataItem[];
  setFormula: (formula: Formula) => void;
}

const Calculation = ({
  isOpen,
  onClose,
  data,
  setFormula,
}: CalculationProps) => {
  const intl = useIntl();
  const [selectedElements, setSelectedElements] = useState<FormulaSet[]>([]);
  const [name, setName] = useState('');
  const [isFormulaValid, setIsFormulaValid] = useState(true);
  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const dataTypes = checkDataTypes(data);

  const options = Object.keys(data[0]).map(key => {
    const dataType = dataTypes[key];
    const aggregationTypes = getAggregationTypes(dataType);
    const type = Object.keys(aggregationTypes)[0] as AggregationTypes;
    return { key, type };
  });

  const [allElements, setAllElements] = useState(options);

  const saveTitle = intl.formatMessage({
    id: 'Common.save',
    defaultMessage: 'Сохранить',
  });

  const calculationTitle = intl.formatMessage({
    id: 'Common.Calculation',
    defaultMessage: 'Калькуляция',
  });

  const formulaNameTitle = intl.formatMessage({
    id: 'Common.FormulaName',
    defaultMessage: 'Название формулы',
  });

  const invalidFormulaTitle = intl.formatMessage({
    id: 'Common.InvalidFormula',
    defaultMessage: 'Невалидная формула',
  });

  const reset = () => {
    setSelectedElements([]);
    setName('');
  };

  const submit = () => {
    const isValid = checkIsFormulaValid(selectedElements);
    setIsFormulaValid(isValid);
    if (isValid) {
      setFormula({
        name,
        formula: selectedElements,
        id: uuid(),
        place: FormulaPlace.row,
      });
      onClose();
      reset();
    }
  };

  const onCancel = () => {
    onClose();
    reset();
  };

  const handleChangeFormula = (value: FormulaSet[]) => {
    setSelectedElements(value);
    setIsFormulaValid(true);
  };

  const handleDeleteSelected = (id: string) => {
    const changed = selectedElements.filter(el => el.id !== id);
    handleChangeFormula(changed);
  };

  const itemsListRender = (item: CalcItem) => (
    <ListItem
      item={item}
      dataTypes={dataTypes}
      allElements={allElements}
      selectedElements={selectedElements}
      setAllElements={setAllElements}
      setSelectedElements={handleChangeFormula}
    />
  );

  const isSaveDisabled = !selectedElements.length || !name.length;

  return (
    <Modal
      title={calculationTitle}
      okText={saveTitle}
      onCancel={onCancel}
      okButtonProps={{ disabled: isSaveDisabled }}
      onOk={submit}
      centered
      open={isOpen}
    >
      <div className={styles.container}>
        <Input
          value={name}
          onChange={handleChangeName}
          placeholder={formulaNameTitle}
        />
        <List
          size="small"
          className={styles.listContainer}
          bordered
          dataSource={allElements}
          renderItem={itemsListRender}
        />
        <div className={styles.buttonsContainer}>
          <Operators
            selectedElements={selectedElements}
            setSelectedElements={handleChangeFormula}
          />
        </div>
        <div className={styles.tags}>
          {selectedElements.map(el => (
            <Tag
              key={el.id}
              closable
              onClose={() => handleDeleteSelected(el.id)}
            >
              {el.label}
              {el.type ? `(${aggregationDropdownItems[el.type]})` : ''}
            </Tag>
          ))}
        </div>
        {!isFormulaValid && (
          <p className={styles.error}>{invalidFormulaTitle}</p>
        )}
      </div>
    </Modal>
  );
};

export default Calculation;
