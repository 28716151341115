import { AggregationTypes, DataTypes } from '../../../types/table';
export const enum Operators {
  plus = '+',
  minus = '-',
  multiplication = '*',
  division = '/',
  openedParenthesis = '(',
  closedParenthesis = ')',
}

export interface CalcItem {
  key: string;
  label?: string;
  type: AggregationTypes;
}

export interface FormulaSet {
  key: string | Operators;
  type?: AggregationTypes;
  dataType?: DataTypes;
  label: string;
  id: string;
}
