import React, { memo, useMemo } from 'react';
import { TimePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { TimeWidgetConfig } from '../../types/dateTime';

interface TimeWidgetProps {
  parameter: string | string[];
  config: TimeWidgetConfig;
  onChange: (data: string) => void;
}

const getDate = (
  params: TimeWidgetProps['parameter'],
  format: string,
): Moment => {
  const currentDate = moment();

  if (Array.isArray(params)) {
    const [first] = params;

    return first ? moment(first, format) : currentDate;
  }

  return params ? moment(params, format) : currentDate;
};

const TimeWidget: React.FC<TimeWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const handleChange = (_: any, formattedValue: string) => {
    onChange(formattedValue);
  };

  const { label, padding, format = 'HH:mm' } = config;

  const initialDate = useMemo(() => {
    return getDate(parameter, format);
  }, [parameter, format]);

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <TimePicker
          size="large"
          defaultValue={initialDate}
          onChange={handleChange}
          format={format}
        />
      </Space>
    </div>
  );
};

const MemoizedTimeWidget = memo(TimeWidget);

export default MemoizedTimeWidget;
