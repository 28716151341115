import { FormattedMessage } from 'react-intl';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { hasAccess } from '../../../utils/rights';
import { Permissions } from '../../../features/settings/types/user';

export const SETTINGS_PERMISSIONS = [
  Permissions.DATA_LOAD,
  Permissions.DATA_ETL,
  Permissions.DATA_EDIT,

  Permissions.USER_READ,
  Permissions.USER_EDIT,

  Permissions.GROUP_READ,
  Permissions.GROUP_EDIT,
];

export const DASHBOARD_PERMISSIONS = [
  Permissions.DASHBOARD_EDIT,
  Permissions.DASHBOARD_READ,
];

const sideBarButtons = [
  {
    name: 'dashboard',
    label: (
      <FormattedMessage
        id="dashboard.MyDashboards"
        defaultMessage="Мои дашборды"
      />
    ),
    icon: <UserOutlined />,
    link: '/dashboard',
    permissions: DASHBOARD_PERMISSIONS,
  },
  {
    name: 'settings',
    label: (
      <FormattedMessage id="dashboard.Settings" defaultMessage="Настройки" />
    ),
    icon: <SettingOutlined />,
    link: '/settings',
    permissions: SETTINGS_PERMISSIONS,
  },
];

export const filterByRights = (userPermissions: Permissions[]) =>
  sideBarButtons.filter(btn => hasAccess(btn.permissions, userPermissions));
