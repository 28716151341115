import axios from 'axios';
import { PUBLIC_BASE_URL } from '../config';

const publicApi = axios.create({
  baseURL: PUBLIC_BASE_URL,
  responseType: 'json',
  headers: { 'Content-Type': 'application/json' },
});

export default publicApi;
