import ColumnHeader from './ColumnHeader';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';
import { intl } from '../../../../utils/intl';
import { PlainObject } from '../../../../types/common';
import {
  Column,
  DataItem,
  DataTypes,
  Filter,
  FilterItem,
  Periods,
  PivotGroupConf,
  TableWidgetColumnWithFilters,
  TotalColumns,
} from '../../types/table';
import moment from 'moment';
import { applyFormatters } from './ValuesSelect';
import styles from './TableWidget.module.less';

export const tableDateFormat = 'YYYY-MM-DD';
export const tableTimeFormat = 'HH:mm:ss';
export const ROW_WITHOUT_COLUMN = 'ROW_WITHOUT_COLUMN';
export const TOTAL_COLUMN_KEY = 'TOTAL_COLUMN_KEY';

const yearLabel = intl.formatMessage({
  id: 'Common.Year',
  defaultMessage: 'Год',
});
const monthLabel = intl.formatMessage({
  id: 'Common.Month',
  defaultMessage: 'Месяц',
});
const weekLabel = intl.formatMessage({
  id: 'Common.Week',
  defaultMessage: 'Неделя',
});

export const periodFormats = {
  [Periods.year]: 'YYYY',
  [Periods.month]: 'MMMM',
  [Periods.week]: `[${weekLabel}] w`,
};

export const periodOptions = [
  { label: yearLabel, value: Periods.year },
  { label: monthLabel, value: Periods.month },
  { label: weekLabel, value: Periods.week },
];

const emptyLabel = intl.formatMessage({
  id: 'Common.empty',
  defaultMessage: 'Пусто',
});

export const addKeys = <
  T extends { id?: string; key?: string; children?: T[] },
>(
  data: T[],
): T[] =>
  data?.map(el => ({
    ...el,
    ...(!el?.key && { key: el?.id }),
    ...(el.children?.length && { children: addKeys(el.children) }),
  }));

export const makeTableFilterFromData = (
  data: PlainObject[],
  key: string,
): Filter => {
  const uniqueValues = Array.from(new Set(data.map(item => item[key])));
  return uniqueValues.map(item => {
    return {
      value: item || '',
      text: item || emptyLabel,
    };
  });
};

export const addFiltersToColumns = (
  filteringColumns: Column[],
  dataSource: PlainObject[] = [],
  allData: PlainObject[] = [],
): TableWidgetColumnWithFilters[] => {
  return filteringColumns.map(column => {
    if (column && column.children && column.children.length > 0) {
      return {
        ...column,
        children: addFiltersToColumns(column.children, dataSource),
      };
    }

    return {
      ...column,
      filters: makeTableFilterFromData(allData, column.key),
      filterSearch: true,
      ...(column.children && {
        children: addFiltersToColumns(column.children, dataSource),
      }),
      onFilter: (value: FilterItem['value'], record: PlainObject) =>
        (record[column.key] || '') === value,
    };
  });
};

const renderBoolean = (value: boolean): ReactNode => {
  return value ? (
    <CheckSquareOutlined style={{ color: green.primary }} />
  ) : (
    <CloseSquareOutlined style={{ color: red.primary }} />
  );
};

const renderCell = (value: any) => {
  if (typeof value === 'boolean') {
    return renderBoolean(value);
  }

  return value;
};

export const formatter = ({
  value,
  dataIndex,
  formatters,
}: {
  value: any;
  dataIndex: string;
  formatters: PivotGroupConf['formatters'];
}) => {
  const formatterKeys = Object.keys(formatters);

  if (value && formatterKeys.length > 0) {
    const currentFormatterKey = formatterKeys.find(formatterKey => {
      return dataIndex.includes(formatterKey);
    });

    if (currentFormatterKey) {
      const formatterNames = formatters[currentFormatterKey];

      return applyFormatters(value, formatterNames);
    }
  }

  return value;
};

export const formatDataItem = (
  dataItem: DataItem,
  formatters: PivotGroupConf['formatters'],
) =>
  Object.entries(dataItem).reduce(
    (acc, [dataIndex, value]) => ({
      ...acc,
      [dataIndex]: formatter({ value, dataIndex, formatters }),
    }),
    {},
  );

// Фильтрация столбцов (скрыть/показать при клике на иконку + в столбцах)
export const filteredColumns = (
  columns: Column[],
  totalColumns: TotalColumns,
  filterKeys: string[],
  showTotals: boolean = false,
): Column[] =>
  columns.reduce<Column[]>((acc, el) => {
    const filteredChildren = el.children
      ? filteredColumns(el.children || [], totalColumns, filterKeys, showTotals)
      : [];

    return [
      ...acc,
      {
        ...el,
        children: !filterKeys.includes(el.key)
          ? filteredChildren
          : totalColumns[el.dataIndex] || [],
      },
    ];
  }, []);

export const generatePivotColumns = <T extends Column>({
  columns,
  filterKeys,
  setKeys,
}: {
  columns: T[];
  filterKeys: string[];
  setKeys: Dispatch<SetStateAction<string[]>>;
}): T[] =>
  columns.map(column => ({
    ...column,
    className: column.dataIndex.includes(TOTAL_COLUMN_KEY)
      ? styles.boldCell
      : '',
    title: column.isPivot ? (
      <ColumnHeader
        hasChildren={!!column.children}
        dataKey={column.key}
        filterKeys={filterKeys}
        setKeys={setKeys}
        name={column.title}
      />
    ) : (
      column.title
    ),
    children: generatePivotColumns({
      columns: column.children || [],
      filterKeys,
      setKeys,
    }),
    render: (value: any) => renderCell(value),
  }));

export const checkFormat = (value: string, format: string = tableDateFormat) =>
  moment(value, format, true).isValid();

export const hasDate = (data: DataItem[]): boolean =>
  data?.some(el =>
    Object.values(el).some(value =>
      Array.isArray(value)
        ? hasDate(value)
        : checkFormat(value, tableDateFormat),
    ),
  );

export const createPivotColumn = (
  dataIndex: string,
  title: string = '',
  isPivot: boolean = false,
  children?: Column[],
): Column => ({
  title: title,
  dataIndex: dataIndex,
  key: dataIndex,
  isPivot,
  ...(children?.length && { children }),
});

export const checkKey = (col: string, data: DataItem[]) =>
  data?.some(el =>
    Object.values(el).some(value =>
      Array.isArray(value) ? hasDate(value) : Object.keys(el).includes(col),
    ),
  );

export const getByModifiedKey = <T,>(
  obj: Record<string, T>,
  modifiedKey: string,
): T => {
  const foundKey = Object.keys(obj).find(key =>
    modifiedKey.endsWith(key),
  ) as string;
  return obj[foundKey];
};

const checkType = (values: ReactNode[]): DataTypes => {
  if (values.some(el => Number.isFinite(el))) {
    return DataTypes.NUMBER;
  } else if (values.some(el => checkFormat(String(el), tableDateFormat))) {
    return DataTypes.DATE;
  } else if (values.some(el => checkFormat(String(el), tableTimeFormat))) {
    return DataTypes.TIME;
  }
  return DataTypes.OTHER;
};

export const checkDataTypes = (data: DataItem[]): Record<string, DataTypes> =>
  Object.keys(data[0]).reduce((acc, key) => {
    const values = data.map(el => el[key]);
    return { ...acc, [key]: checkType(values) };
  }, {});
