import React, { useEffect } from 'react';
import styles from './PrintModal.module.less';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataType } from '../../../types/dashboard';
import TemplateView from './TemplateView';
import AddEditTemplate from './AddEditTemplate';
import { useSelector } from 'react-redux';
import {
  fetchTemplateDetail,
  selectLoadingTemplate,
  selectTemplate,
} from '../../../dashboardSlice';
import { Spin } from 'antd';
import { WidgetTypes } from '../../../../../components/Widget/types/common';
import { useAppDispatch } from '../../../../../store';

interface ITemplateModalProps {
  onClose: () => void;
  isView: boolean;
  templateId: string;
  selected: {
    name: string;
    id: string;
    dataType: DataType;
    widgetType?: WidgetTypes;
  };
}

const TemplateModal = ({
  onClose,
  isView,
  templateId,
  selected,
}: ITemplateModalProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const template = useSelector(selectTemplate);
  const templateLoading = useSelector(selectLoadingTemplate);
  const editTemplateTitle = intl.formatMessage({
    id: 'SendingTemplate.Editing',
    defaultMessage: 'Редактирование шаблона рассылки',
  });
  const addTemplateTitle = intl.formatMessage({
    id: 'SendingTemplate.Adding',
    defaultMessage: 'Добавление шаблона рассылки',
  });

  const modalContent = !isView ? (
    <AddEditTemplate
      template={template}
      selected={selected}
      onClose={onClose}
    />
  ) : (
    <TemplateView template={template} />
  );

  const editAddTitle = templateId ? editTemplateTitle : addTemplateTitle;

  useEffect(() => {
    if (templateId) {
      dispatch(fetchTemplateDetail(templateId));
    }
  }, [templateId]);

  return (
    <div className={styles.templateModal}>
      <div className={styles.templateHeader}>
        {isView ? (
          <FormattedMessage
            id="SendingTemplate.Template"
            defaultMessage="Шаблон рассылки"
          />
        ) : (
          editAddTitle
        )}
      </div>
      {templateLoading ? <Spin className={styles.spinStyle} /> : modalContent}
    </div>
  );
};

export default TemplateModal;
