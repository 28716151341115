import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import API from '../../utils/API';
import {
  ContactTypes,
  CreateEditTemplateType,
  Dashboard,
  DashboardListItem,
  DataType,
  FileType,
  SendDashboardParams,
  TemplateType,
  WidgetGrid,
  WidgetsDataType,
} from './types/dashboard';
import { ResponseData } from '../../types/api';
import { setError } from '../error/errorSlice';
import { PlainObject } from '../../types/common';
import { NewWidgetGridType } from './types/widget';
import { downloadBinaryObj } from '../../utils/downloadBinaryObj';
import { WidgetTypes } from '../../components/Widget/types/common';

export type DashboardState = {
  list: DashboardListItem[];
  current: Dashboard | null;
  totalPages: number;
  totalElements: number;
  loading: boolean;
  loadingEditTemplate: boolean;
  loadingTemplate: boolean;
  loadingSendDashboardWidget: boolean;
  changed: boolean;
  activeWidgetGrid: WidgetGrid | null;
  widgetsData: WidgetsDataType;
  statusMap: PlainObject;
  contacts: Array<{ value: string }>;
  templates: TemplateType[];
  template: TemplateType | null;
  widgetErrors: { id: string; message: string }[];
  draggedWidgetType: WidgetTypes | null;
};

const initialState: DashboardState = {
  list: [],
  current: null,
  loading: false,
  totalPages: 0,
  totalElements: 0,
  changed: false,
  activeWidgetGrid: null,
  widgetsData: {},
  statusMap: {},
  contacts: [],
  templates: [],
  template: null,
  loadingEditTemplate: false,
  loadingSendDashboardWidget: false,
  loadingTemplate: false,
  widgetErrors: [],
  draggedWidgetType: null,
};

interface DashboardListResponseResult {
  totalPages: number;
  totalElements: number;
  content: DashboardListItem[];
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loading: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    receiveDashboard: (
      state: DashboardState,
      action: PayloadAction<Dashboard>,
    ) => {
      state.current = action.payload;
      state.changed = false;
    },
    change: (state: DashboardState, action: PayloadAction<Dashboard>) => {
      state.current = action.payload;
      state.changed = true;
    },
    receiveDashboardList: (
      state: DashboardState,
      action: PayloadAction<DashboardListResponseResult>,
    ) => {
      const { totalPages, totalElements, content } = action.payload;

      return { ...state, list: content, totalPages, totalElements };
    },
    receiveWidgetGrid: (
      state: DashboardState,
      action: PayloadAction<WidgetGrid>,
    ) => {
      const widgetGrid = state.current?.widgetGrids.find(
        item => item.id === action.payload.id,
      );

      state.activeWidgetGrid = action.payload;

      if (widgetGrid) {
        widgetGrid.widget = action.payload.widget;
      }
    },
    setWidgetParameter: (
      state: DashboardState,
      action: PayloadAction<{ widgetId: string; data: any }>,
    ) => {
      const { widgetId, data } = action.payload;
      const widgetGrid: WidgetGrid | undefined =
        state.current?.widgetGrids.find(wg => wg.widget.id === widgetId);

      if (widgetGrid) {
        widgetGrid.widget.parameter = data;
      }
    },
    remove: (state: DashboardState, action: PayloadAction<string>) => {
      return {
        ...state,
        list: state.list.filter(dashboard => dashboard.id !== action.payload),
      };
    },

    setActiveWidgetGrid: (
      state: DashboardState,
      action: PayloadAction<WidgetGrid | null>,
    ) => {
      state.activeWidgetGrid = action.payload;
    },

    receiveWidgetsData: (
      state: DashboardState,
      action: PayloadAction<{ widgetId: string; data: WidgetsDataType }>,
    ) => {
      const { widgetId, data } = action.payload;

      if (!state.widgetsData[widgetId]) {
        state.widgetsData[widgetId] = null;
      }
      state.widgetsData[widgetId] = data;
    },
    receivePrintWidgetGrid: (
      state: DashboardState,
      { payload }: PayloadAction<WidgetGrid>,
    ) => {
      state.current = {
        id: '',
        name: '',
        widgetGrids: [
          { ...payload, position: { ...payload.position, x: 0, y: 0 } },
        ],
      };
    },
    receiveContacts: (
      state,
      { payload }: PayloadAction<{ value: string }[]>,
    ) => {
      state.contacts = payload;
    },
    receiveTemplates: (state, { payload }: PayloadAction<TemplateType[]>) => {
      state.templates = payload;
    },
    receiveTemplate: (state, { payload }: PayloadAction<TemplateType>) => {
      state.template = payload;
    },
    resetTemplate: state => {
      state.template = null;
    },
    loadingCreateEditTemplate: (
      state: DashboardState,
      action: PayloadAction<boolean>,
    ) => {
      state.loadingEditTemplate = action.payload;
    },
    setLoadingSendDashboardWidget: (
      state: DashboardState,
      action: PayloadAction<boolean>,
    ) => {
      state.loadingSendDashboardWidget = action.payload;
    },
    loadingTemplate: (
      state: DashboardState,
      action: PayloadAction<boolean>,
    ) => {
      state.loadingTemplate = action.payload;
    },
    setWidgetError: (
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) => {
      state.widgetErrors.push(action.payload);
    },
    deleteWidgetError: (state, action: PayloadAction<string>) => {
      state.widgetErrors = state.widgetErrors.filter(
        el => el.id !== action.payload,
      );
    },
    setDraggedWidgetType: (state, { payload }: PayloadAction<WidgetTypes>) => {
      state.draggedWidgetType = payload;
    },
    reset: () => initialState,
  },
});

export const {
  loading,
  receiveDashboardList,
  receiveDashboard,
  reset,
  change,
  remove,
  setActiveWidgetGrid,
  receiveWidgetsData,
  receiveWidgetGrid,
  setWidgetParameter,
  receivePrintWidgetGrid,
  receiveContacts,
  receiveTemplates,
  receiveTemplate,
  resetTemplate,
  loadingCreateEditTemplate,
  loadingTemplate,
  setWidgetError,
  deleteWidgetError,
  setLoadingSendDashboardWidget,
  setDraggedWidgetType,
} = dashboardSlice.actions;

/**
 * Fetch a dashboard by id
 */
export const fetchDashboard =
  (dashboardId: string, withData: boolean = false): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      const response = await API.get(`dashboard/${dashboardId}`, {
        params: { withData },
      });
      if (withData && response.data?.result?.widgetGrids?.length) {
        response.data?.result?.widgetGrids.forEach((el: WidgetGrid) =>
          dispatch(
            receiveWidgetsData({ widgetId: el.widget.id || '', data: el.data }),
          ),
        );
      }
      dispatch(receiveDashboard(response.data.result));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const fetchWidget =
  (widgetGridId: string, withData: boolean = false): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.get<{ result: WidgetGrid }>(
        `dashboard/widget/${widgetGridId}`,
        {
          params: { withData },
        },
      );

      if (withData) {
        dispatch(
          receiveWidgetsData({
            widgetId: data.result.widget.id || '',
            data: data.result.data,
          }),
        );
      }

      dispatch(receivePrintWidgetGrid(data.result));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

/**
 * Fetch all dashboards
 */
export const fetchDashboardList = (): AppThunk => async dispatch => {
  dispatch(loading(true));
  try {
    const data = {
      page: 0,
      size: 0,
      sort: {
        field: 'name',
        type: 'ASC',
      },
    };
    const response = await API.post<ResponseData<DashboardListResponseResult>>(
      'dashboard/list/user',
      data,
    );

    dispatch(receiveDashboardList(response.data.result));
  } catch (e: any) {
    dispatch(setError(e));
  } finally {
    dispatch(loading(false));
  }
};

export const updateDashboard =
  (dashboard: Dashboard): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      await API.put('dashboard', dashboard);
      if (dashboard.id) {
        dispatch(fetchDashboard(dashboard.id));
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const addWidget =
  (dashboard: Dashboard, widgetGrid: NewWidgetGridType): AppThunk =>
  async dispatch => {
    try {
      await API.put('dashboard', dashboard);
      await API.post(`dashboard/${dashboard.id}`, widgetGrid);

      dispatch(fetchDashboard(dashboard.id));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const createEmptyDashboard =
  (name: string, successCb?: (id: string) => void): AppThunk =>
  async dispatch => {
    try {
      const data = { name, widgetGrids: [] };
      const response = await API.post('dashboard', data);
      if (typeof successCb === 'function') {
        successCb(response.data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const removeDashboard =
  (dashboardId: string): AppThunk =>
  async dispatch => {
    try {
      await API.delete(`dashboard/${dashboardId}`);
      dispatch(remove(dashboardId));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

/**
 * Change dashboard item
 *
 * @param dashboardId - current dashboardId
 * @param widgetGrid - changed dashboard item
 */
export const updateWidgetGrid =
  (dashboardId: string, widgetGrid: WidgetGrid): AppThunk =>
  async dispatch => {
    try {
      const response = await API.put(
        `dashboard/dashboard/${dashboardId}/widget`,
        widgetGrid,
      );
      dispatch(receiveWidgetGrid(response.data.result));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

/**
 * Delete widget in dashboard
 *
 * @param {string} dashboard - current dashboard
 * @param {string} widgetGridId - deleting item
 */
export const deleteWidget =
  (dashboard: Dashboard, widgetGridId: string): AppThunk =>
  async dispatch => {
    try {
      await API.put('dashboard', dashboard);
      await API.delete(
        `dashboard/dashboard/${dashboard.id}/widget/${widgetGridId}`,
      );
      dispatch(fetchDashboard(dashboard.id));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const updateWidgetParameter =
  (widgetId: string, data: any): AppThunk =>
  async dispatch => {
    try {
      await API.put(`widget/${widgetId}/changeParameter`, data);
      dispatch(setWidgetParameter({ widgetId, data }));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const downloadDashboardWidget =
  (
    id: string,
    dataType: DataType,
    fileType: FileType,
    successCb?: () => void,
    finallyCb?: () => void,
  ): AppThunk =>
  async dispatch => {
    try {
      const file = await API.get<Blob>(
        `${dataType}/${id}/download/${fileType}`,
        {
          responseType: 'blob',
        },
      );
      const fileName = file.headers?.['original-name'];
      downloadBinaryObj(file.data, fileName);
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      finallyCb?.();
    }
  };

export const sendDashboardWidget =
  (params: SendDashboardParams, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(setLoadingSendDashboardWidget(true));
    const { dataType, fileType, id, contacts } = params;
    try {
      await API.post(`${dataType}/${id}/send/${fileType}`, contacts);
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(setLoadingSendDashboardWidget(false));
    }
  };

export const fetchEmails =
  (search: string, types?: ContactTypes): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.get('contact', {
        params: { search, types },
      });
      dispatch(receiveContacts(data.result));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const fetchTemplates =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.get('notification/template', {
        params: { id },
      });
      dispatch(receiveTemplates(data.result));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const fetchTemplateDetail =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(loadingTemplate(true));
    try {
      const { data } = await API.get(`notification/template/${id}`);
      dispatch(receiveTemplate(data.result));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loadingTemplate(false));
    }
  };

export const createAndEditTemplate =
  (template: CreateEditTemplateType, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(loadingCreateEditTemplate(true));
    try {
      await API.post('notification/template', template);
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loadingCreateEditTemplate(false));
    }
  };

export const deleteTemplate =
  (id: string, successCb?: () => void): AppThunk =>
  async dispatch => {
    try {
      await API.delete(`notification/template/${id}`);
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

// selectors
export const selectDashboardList = (state: RootState): DashboardListItem[] =>
  state.dashboard.list;
export const isDashboardLoading = (state: RootState): boolean =>
  state.dashboard.loading;
export const selectCurrentDashboard = (state: RootState): Dashboard | null =>
  state.dashboard.current;
export const isDashboardChanged = (state: RootState): boolean =>
  state.dashboard.changed;
export const selectActiveWidgetGrid = (state: RootState): WidgetGrid | null =>
  state.dashboard.activeWidgetGrid;
export const selectWidgetData = (state: RootState, widgetId?: string) =>
  widgetId &&
  state.dashboard.widgetsData &&
  state.dashboard.widgetsData[widgetId];
export const selectContacts = (state: RootState): DashboardState['contacts'] =>
  state.dashboard.contacts;
export const selectTemplates = (
  state: RootState,
): DashboardState['templates'] => state.dashboard.templates;
export const selectLoadingEditTemplate = (
  state: RootState,
): DashboardState['loadingEditTemplate'] => state.dashboard.loadingEditTemplate;
export const selectLoadingTemplate = (
  state: RootState,
): DashboardState['loadingTemplate'] => state.dashboard.loadingTemplate;
export const selectTemplate = (state: RootState): DashboardState['template'] =>
  state.dashboard.template;
export const selectLoadingSendDashboardWidget = (
  state: RootState,
): DashboardState['loadingSendDashboardWidget'] =>
  state.dashboard.loadingSendDashboardWidget;
export const selectDraggedWidgetType = (
  state: RootState,
): DashboardState['draggedWidgetType'] => state.dashboard.draggedWidgetType;

export const selectWidgetErrors = createSelector(
  (state: RootState) => state.dashboard.widgetErrors,
  (_: RootState, widgetId: string | undefined) => widgetId,
  (widgetErrors, widgetId) => widgetErrors.find(el => el.id === widgetId),
);
export default dashboardSlice.reducer;
