import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IntlShape, useIntl } from 'react-intl';
import { DashboardListItem } from '../../types/dashboard';
import { useSelector } from 'react-redux';
import { removeDashboard } from '../../dashboardSlice';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { Permissions } from '../../../settings/types/user';
import { hasAccess } from '../../../../utils/rights';
import { useAppDispatch } from '../../../../store';

interface Props {
  dashboard: DashboardListItem;
  title?: string;
}

const getConfirmTitle = (
  dashboard: DashboardListItem,
  intl: IntlShape,
): string =>
  intl.formatMessage(
    {
      id: 'dashboard.RemoveDashboard',
      description: 'Removing dashboard alert',
      defaultMessage: 'Вы действительно хотите удалить {dashboardName}?',
    },
    { dashboardName: dashboard.name },
  );

const DeleteDashboardBtn: React.FC<Props> = ({ dashboard, title }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const grants = useSelector(selectCurrentUserGrants);
  const deleteDashPermission = hasAccess([Permissions.DASHBOARD_EDIT], grants);
  const handleConfirm = () => dispatch(removeDashboard(dashboard.id));

  return (
    <Popconfirm
      title={getConfirmTitle(dashboard, intl)}
      onConfirm={handleConfirm}
    >
      <Button
        title={title}
        icon={<DeleteOutlined />}
        hidden={!deleteDashPermission}
        type="link"
        size="small"
      />
    </Popconfirm>
  );
};

export default DeleteDashboardBtn;
