import React from 'react';
import { getAggregationTypes } from '../aggregators';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, List, MenuProps } from 'antd';
import { AggregationTypes, DataTypes } from '../../../types/table';
import { uuid } from '../../../../../utils';
import styles from './Calculation.module.less';
import { CalcItem, FormulaSet } from './types';

interface ListItemProps {
  item: CalcItem;
  allElements: CalcItem[];
  selectedElements: FormulaSet[];
  setAllElements: (value: CalcItem[]) => void;
  setSelectedElements: (value: FormulaSet[]) => void;
  dataTypes: Record<string, DataTypes>;
}

const ListItem = ({
  item,
  selectedElements,
  allElements,
  setSelectedElements,
  setAllElements,
  dataTypes,
}: ListItemProps) => {
  const dataType = dataTypes[item.key];
  const aggregationTypes = getAggregationTypes(dataType);

  const items = Object.keys(aggregationTypes).map(key => ({
    key,
    label: aggregationTypes[key as keyof typeof aggregationTypes],
    ...(item.type === key && { icon: <CheckOutlined /> }),
  }));

  const handleMenuClick: MenuProps['onClick'] = e => {
    e.domEvent.stopPropagation();
    const changed = allElements.map(el =>
      el.key === item.key ? { ...el, type: e.key as AggregationTypes } : el,
    );
    setAllElements(changed);
  };

  const handleItemSelect = () =>
    setSelectedElements([
      ...selectedElements,
      { ...item, label: item.label || item.key, id: uuid(), dataType },
    ]);

  const menu = (
    <Dropdown trigger={['click']} menu={{ items, onClick: handleMenuClick }}>
      <DownOutlined onClick={e => e.stopPropagation()} />
    </Dropdown>
  );

  return (
    <List.Item
      onClick={handleItemSelect}
      className={styles.listItem}
      actions={[menu]}
    >
      {item.label || item.key} (
      {aggregationTypes[item.type as keyof typeof aggregationTypes]})
    </List.Item>
  );
};

export default ListItem;
