import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './TableWidget.module.less';

interface ColumnHeaderProps {
  name: string;
  filterKeys: string[];
  setKeys: Dispatch<SetStateAction<string[]>>;
  dataKey: string;
  hasChildren: boolean;
}

const ColumnHeader = ({
  name,
  filterKeys,
  setKeys,
  dataKey,
  hasChildren,
}: ColumnHeaderProps) => {
  const isClosed = filterKeys.includes(dataKey);
  const handleExpandToggleClick = () =>
    setKeys(prevState =>
      prevState.includes(dataKey)
        ? prevState.filter(el => el !== dataKey)
        : [...prevState, dataKey],
    );

  useEffect(() => {
    if (hasChildren) {
      setKeys(prevState => [...prevState, dataKey]);
    }
  }, []);

  const expandIcon = isClosed ? <PlusOutlined /> : <MinusOutlined />;

  return (
    <div className={styles.columnHeader}>
      {name}
      {hasChildren && (
        <Button
          size="small"
          className={styles.expandButton}
          onClick={handleExpandToggleClick}
          shape="default"
          icon={expandIcon}
        />
      )}
    </div>
  );
};

export default ColumnHeader;
