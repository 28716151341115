import React, { memo, useMemo } from 'react';
import { DatePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { DateWidgetConfig } from '../../types/dateTime';
import { widgetDateFormat } from '../../../../utils/dateFormats';

interface DateWidgetProps {
  config: DateWidgetConfig;
  onChange: (data: string) => void;
  parameter: string | string[];
}

const getDate = (
  params: DateWidgetProps['parameter'],
  format: string,
): Moment => {
  const currentDate = moment();

  if (Array.isArray(params)) {
    const [first] = params;

    return first ? moment(first, format) : currentDate;
  }

  return params ? moment(params, format) : currentDate;
};

const DateWidget: React.FC<DateWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const handleChange = (_: any, formattedValue: string) => {
    onChange(formattedValue);
  };

  const { label, padding } = config;

  const initialDate = useMemo(() => {
    return getDate(parameter, widgetDateFormat);
  }, [parameter]);

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <DatePicker
          size="large"
          format={widgetDateFormat}
          value={initialDate}
          onChange={handleChange}
        />
      </Space>
    </div>
  );
};

const MemoizedDateWidget = memo(DateWidget);

export default MemoizedDateWidget;
