import { FormulaSet, Operators } from './types';
import {
  AggregationTypes,
  DataItem,
  DataTypes,
  Formula,
} from '../../../types/table';
import { formatTotal } from '../aggregators';
import { ReactNode } from 'react';
import { evaluate, parse } from 'mathjs';
import moment from 'moment';
import { tableTimeFormat } from '../helpers';

export const operatorOptions = {
  [Operators.plus]: '+',
  [Operators.minus]: '-',
  [Operators.multiplication]: '×',
  [Operators.division]: '÷',
  [Operators.openedParenthesis]: '(',
  [Operators.closedParenthesis]: ')',
};

const getCalculatedValue = (
  data: DataItem[],
  type: AggregationTypes,
  key: string,
  dataType?: DataTypes,
) => {
  const values = data.reduce<ReactNode[]>((acc, el) => [...acc, el[key]], []);
  const isTime = dataType === DataTypes.TIME;
  const result = formatTotal(values, type);
  return isTime ? moment.duration(result).as('milliseconds') : result;
};

export const calculateByFormula = (
  data: DataItem[],
  formulas: Formula[],
): DataItem => {
  return formulas.reduce((acc, formulaItem) => {
    const calculated = formulaItem.formula.map(el =>
      el?.type
        ? getCalculatedValue(data, el.type, el.key, el.dataType)
        : el.key,
    );
    const expr = calculated.map(el => el || 0).join('');
    const hasNum = calculated.some(el => Number.isFinite(el));
    const hasTime = formulaItem.formula.some(
      el => el?.dataType === DataTypes.TIME,
    );
    const calculationResult = hasNum || hasTime ? evaluate(expr) : null;
    const result = hasTime
      ? moment.utc(calculationResult).format(tableTimeFormat)
      : calculationResult;
    return { ...acc, [formulaItem.id]: result };
  }, {});
};

export const checkIsFormulaValid = (formula: FormulaSet[]) => {
  const expr = formula.map(el => (el?.type ? 1 : el.key)).join('');
  try {
    parse(expr);
    return true;
  } catch (_) {
    return false;
  }
};
