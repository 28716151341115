import { WidgetConfig } from './common';
import { RowSelectionType } from 'antd/es/table/interface';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PlainObject } from '../../../types/common';
import { Formatters } from '../components/TableWidget/ValuesSelect';
import { FormulaSet } from '../components/TableWidget/Calculation/types';
import { ReactNode } from 'react';

export type DataItem = Record<Column['dataIndex'], any> & {
  children?: DataItem[];
};

export type FilterItem = {
  text: string;
  value: string | number | boolean;
};

export interface Column {
  title: string;
  dataIndex: string;
  key: string;
  type?: AggregationTypes;
  children?: Column[];
  isPivot?: boolean;
}

export interface TableWidgetColumnWithFilters extends Column {
  filters?: FilterItem[];
  onFilter?: (value: FilterItem['value'], record: PlainObject) => boolean;
}

export interface TableWidgetConfig extends WidgetConfig {
  columns?: Column[];
  maxRowCount?: number;
  rowSelection?: boolean;
  rowSelectionType?: RowSelectionType;
  keyFieldName?: string;
  bordered?: boolean;
  size?: SizeType;
  groupColumns?: GroupingColumnsConfig[];
  groupValues?: GroupingRowsConfig[];
  settings?: PivotGroupConf;
}

export const enum AggregationTypes {
  sum = 'sum',
  min = 'min',
  max = 'max',
  average = 'average',
  count = 'count',
}

export interface GroupingColumnsConfig {
  title: string;
  type?: AggregationTypes;
  feature: Column['dataIndex'][];
}

export interface GroupingRowsConfig {
  column: Column['dataIndex'];
  type: AggregationTypes;
}

export const enum Periods {
  month = 'group-by-month',
  week = 'group-by-week',
  year = 'group-by-year',
}

export const enum FormulaPlace {
  row = 'row',
  column = 'column',
}

export interface Formula {
  name: string;
  formula: FormulaSet[];
  id: string;
  place: FormulaPlace;
}

export type TotalColumns = Record<string, Column[]>;
export const enum ValuesPlace {
  ROWS = 'ROWS',
  COLUMNS = 'COLUMNS',
}

export interface PivotGroupConf {
  columns: string[];
  rows: string[];
  values: string[];
  groupBy: Record<string, Periods[]>;
  showRowTotal?: boolean;
  showColumnTotal?: boolean;
  formatters: Record<
    string,
    {
      rules: Formatters[];
      options: Record<string, any>;
    }
  >;
  valueTotalTypes: Record<string, AggregationTypes>;
  formulas: Formula[];
  valuesPlace: ValuesPlace;
}

export type Filter = FilterItem[];

export const enum DataTypes {
  DATE = 'DATE',
  TIME = 'TIME',
  NUMBER = 'NUMBER',
  OTHER = 'OTHER',
}

export type AggregatorsSetType = Record<
  AggregationTypes,
  (values: ReactNode[]) => number | string
>;
