import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { periodOptions } from './helpers';
import { useIntl } from 'react-intl';
import styles from './TableWidget.module.less';
import { Periods } from '../../types/table';

interface GroupByProps {
  col: string;
  open: boolean;
  onClose: () => void;
  value: Periods[];
  onChange: (value: Periods[]) => void;
}

const GroupBy = ({ open, col, onClose, value, onChange }: GroupByProps) => {
  const intl = useIntl();
  const [state, setState] = useState<Periods[]>(value);

  const saveTitle = intl.formatMessage({
    id: 'Common.save',
    defaultMessage: 'Сохранить',
  });

  const groupByTitle = intl.formatMessage({
    id: 'Common.GroupBy',
    defaultMessage: 'Сгруппировать по',
  });

  const handleChange = (values: Periods[]) => setState(values);

  const onCancel = () => {
    setState(value);
    onClose();
  };

  const submit = () => {
    onChange(state);
    onClose();
  };

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Modal
      title={col}
      okText={saveTitle}
      onOk={submit}
      onCancel={onCancel}
      centered
      open={open}
    >
      <h4>{groupByTitle}:</h4>
      <Select
        mode="multiple"
        size="large"
        value={state}
        onChange={handleChange}
        options={periodOptions}
        className={styles.select}
      />
    </Modal>
  );
};

export default GroupBy;
