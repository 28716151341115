import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageNotFound from '../components/PageNotFound';
import ProtectedRoute from '../components/ProtectedRoute';
import DashboardList from '../features/dashboard/routes/DashboardList';
import Dashboard from '../features/dashboard/routes/Dashboard';
import { SETTINGS } from '../constants/routes';
import Settings from '../features/settings/routes/Settings';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../features/user/userSlice';
import { hasAccess } from '../utils/rights';
import {
  DASHBOARD_PERMISSIONS,
  SETTINGS_PERMISSIONS,
} from '../components/layout/ManagerMenu/menu';

const routesByPermissions = [
  {
    route: '/dashboard',
    permissions: DASHBOARD_PERMISSIONS,
  },
  {
    route: '/dashboard:dashboardId',
    permissions: DASHBOARD_PERMISSIONS,
  },
  {
    route: '/settings',
    permissions: SETTINGS_PERMISSIONS,
  },
];

const Router = () => {
  const userGrants = useSelector(selectCurrentUserGrants);
  const redirectToRoute = useMemo(() => {
    const availableRoute = routesByPermissions.find(el =>
      hasAccess(el.permissions, userGrants),
    );
    return availableRoute?.route || '*';
  }, [userGrants]);

  return (
    <Switch>
      <ProtectedRoute exact={true} path="/">
        <Redirect to={redirectToRoute} />
      </ProtectedRoute>
      <ProtectedRoute
        accessRightsList={DASHBOARD_PERMISSIONS}
        path="/dashboard/:dashboardId"
      >
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute
        accessRightsList={DASHBOARD_PERMISSIONS}
        path="/dashboard"
      >
        <DashboardList basePath="/dashboard" />
      </ProtectedRoute>
      <ProtectedRoute accessRightsList={SETTINGS_PERMISSIONS} path={SETTINGS}>
        <Settings />
      </ProtectedRoute>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Router;
